<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('隐私政策')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <template v-if="lang == 'zh'">
                <pre>
    我司是一家根據美國相關法律在美國註冊成立的公司，該公司運營全球APP（以下稱“本平台”或“平台”），該平台是一個專門供用戶進行數字資產交易和提供相關服務（以下稱“該服務”或“服務”）的平台。為了表述之方便，公司和該平台在本協議中合稱“我們”或其他第一人稱稱呼，只要登陸該平台的自然人或其他主體均為本平台的用戶，以下使用“您”或其他第二人稱，我們和您合稱為“雙方”，我們或您單稱為“一方”。

我們充分理解與尊重您的個人信息對您的重要性，我們將按法律法規要求，採取相應安全保護措施，保護您的個人信息安全。鑑於此，制定本《隱私政策》（下稱“本政策 /本隱私政策”）並提醒您：

本政策適用於我們提供的所有產品和服務。如我們向您提供的產品或服務單獨設立有隱私政策的，則該產品或服務適用其單獨設立的隱私政策；如我們的產品或服務未單獨設立隱私政策的，則適用於本政策。

需要特別說明的是，本政策不適用於其他第三方向您提供的服務，第三方向您提供的服務適用其向您另行說明的隱私政策。

在使用我們各項產品或服務前，請您務必仔細閱讀並透徹理解本政策，特別是以粗體/粗體下劃線標識的條款，您應重點閱讀，在確認充分理解並同意後再開始使用。如對本政策內容有任何疑問、意見或建議，您可通過我們官方提供的各種聯繫方式與我們聯繫。如您不同意本政策中的任何條款，您應立即停止訪問本平台。

第一部分 定義

個人信息：指以電子或者其他方式記錄的能夠單獨或者與其他信息結合識別特定自然人身份或者反映特定自然人活動情況的各種信息。

個人敏感信息：指包括證件件號碼、個人生物識別信息、銀行賬號、財產信息、行踪軌跡、交易信息、14歲以下（含）兒童信息等的個人信息（我們將在本隱私權政策中對具體個人敏感信息以粗體進行顯著標識）。

個人信息刪除：指在實現日常業務功能所涉及的系統中去除個人信息的行為，使其保持不可被檢索、訪問的狀態。

兒童：指不滿十四周歲的未成年人。

第二部分 隱私政策

本隱私政策部分將幫助您了解以下內容：

一、我們如何收集和使用您的信息

二、我們如何使用Cookie和同類技術

三、我們如何共享、轉讓、公開披露您的信息

四、我們如何保護您的信息

五、您如何管理您的信息

六、我們如何處理未成年人的信息

七、本隱私政策如何更新

八、如何联系我們

一、 我們如何收集和使用您的信息

在您使用我們的產品及/或服務時，我們需要/可能需要收集和使用的您的個人信息包括如下兩種：

1、為實現向您提供我們產品及/或服務的基本功能，您須授權我們收集、使用的必要的信息。如您拒絕提供相應信息，您將無法正常使用我們的產品及/或服務；

2、為實現向您提供我們產品及/或服務的附加功能，您可選擇授權我們收集、使用的信息。如您拒絕提供，您將無法正常使用相關附加功能或無法達到我們擬達到的功能效果，但並不會影響您正常使用我們產品及/或服務的基本功能。

您理解並同意：

1、我們致力於打造多樣的產品和服務以滿足您的需求。因我們向您提供的產品和服務種類眾多，且不同用戶選擇使用的具體產品/服務範圍存在差異，相應的，基本/附加功能及收集使用的個人信息類型、範圍等會有所區別，請以具體的產品/服務功能為準；

2、為給您帶來更好的產品和服務體驗，我們在持續努力改進我們的技術，隨之我們可能會不時推出新的或優化後的功能，可能需要收集、使用新的個人信息或變更個人信息使用目的或方式。對此，我們將通過更新本政策、彈窗、頁面提示等方式另行向您說明對應信息的收集目的、範圍及使用方式，並為您提供自主選擇同意的方式，且在徵得您明示同意後收集、使用。在此過程中，如果您有任何疑問、意見或建議的，您可通過本平台提供的各種聯繫方式與我們聯繫，我們會盡快為您作出解答。

我們會為實現本政策下述的各項功能，收集和使用您的個人信息：

（一） 幫助您註冊，並向您提供本平台所提供的服務；

1、註冊服務

我們通過本平台賬戶為您提供基礎會員服務。為註冊成為會員並使用我們的會員服務，您在申請註冊時需要填寫和提供以下信息：

（1）身份信息。該信息可以幫助我們驗證您是否有資格註冊為本平台會員，包括但不限於您的名字、您的居住地址、您所屬國家或政府簽發的其他具有證明您身份信息的不同的證書以及涉及到的號碼及所有其他可幫助我們驗證您身份的信息（以下合稱為“身份信息” ）。該身份信息具體根據您是個人用戶還是機構用戶而有所不同，根據不同司法管轄區的法律和法規，我們針對來自不同國家或地區的用戶所收集的信息可能有所不同。以下信息和數據作為一個指示性的示例：

個人用戶：國籍、法定全名（包括曾用名和當地語言名稱）、證件號、電子郵件地址、電話號碼、社會安全號碼（“SSN”），護照號碼或任何政府頒發的證件號碼、出生日期、證件明（例如護照，駕駛執照或政府頒發的證件明）。

機構用戶：公司法定名稱（包括當地語言的法定名稱）、註冊信息、商務電話號碼、授權代表及授權用戶個人信息、主要營業地點、所有實益擁有人的個人信息）、股東/實益擁有人的證件明文件（護照或政府簽發的證件）、公司架構、公司成立相關文件、董事會決議、資金來源申報、風險與合規控制說明及平台要求的其他信息或者文件。

（2）服務信息。該信息幫助我們與您聯繫並順利為您提供不同模式的數字資產間交易服務，包括但不限於您的個人身份信息、聯繫信息、交易信息、以及您的借記卡信息和/或其他帳戶信息（以下合稱為“服務信息” ）。同時請您理解，我們向您提供的服務是不斷更新和發展的。如您選擇使用了前述說明當中尚未涵蓋的其他服務，基於該服務我們需要收集您的額外信息的，我們會通過頁面提示、交互流程、協議約定、彈窗提示等方式向您說明信息收集的範圍與目的，並徵得您的同意。

如果您僅需使用瀏覽服務，您不需要註冊成為我們的會員及提供上述信息。

2、其他註冊服務

實名認證：當您註冊登錄後，需進入"個人中心"在身份認證中完成實名認證，您在使用實名認證過程中需向平台提供您的真實姓名、證件號或護照號。

高級認證：當您在本平台中的交易額超過一定限額時，平台為了維護您的資產安全，會要求對您進行高級認證。認證過程除實名認證信息外，您認可平台對您進行“人臉識別”的高級認證，人臉識別會涉及到平台對您的相關視頻流或人臉圖像的收集。

賬戶信息展示：如果您已擁有本平台賬戶，我們可能會在服務中顯示您的上述個人信息（實名認證僅顯示認證是否通過的結果），以及您在平台賬戶相關聯的產品和服務中執行的操作，包括通過本平台賬戶集中展示您的個人資料、交易訂單。我們會尊重您對平台賬戶設置所做的選擇。

（二） 安全管理服務和服務信息推送服務

當您使用本平台提供的服務或訪問本平台時，本平台為保障您的交易安全及服務的安全穩定運行，我們需要自動接收並記錄您的Web瀏覽器信息，您登錄賬戶使用的設備類型、型號版本等設備識別符號等設備信息、您請求訪問的網頁上的IP地址及記錄、所在地區、網絡環境、設備指紋信息、服務日誌信息、軟件信息、位置信息等。

請注意，單獨的設備信息是無法識別特定自然人身份的信息。如果我們將這類非個人信息與其他信息結合用於識別特定自然人身份，或者將其與個人信息結合使用，則在結合使用期間，這類非個人信息將被視為個人信息，除取得您授權或法律法規另有規定外，我們會將這類信息做匿名化、去標識化處理。

為向您提供更便捷、更符合您個性化需求的信息展示及推送服務，我們未來可能會根據您的設備信息和您本平台或客戶端的瀏覽使用信息，提取您的偏好特徵，並基於特徵標籤產出間接人群畫像，用於展示、推送信息和可能的商業廣告。若您不同意接收此類推送信息或撤回同意收集個人信息，您可根據本政策提供的聯繫方式與我們聯繫。

我們努力保障您的瀏覽體驗。如果您不想接受我們給您發送的商業廣告，您可通過短信提示回復退訂或我們提供的其他方式進行退訂或關閉。

此外，我們也會為了不斷改進和優化上述的功能來使用您的上述信息。

（三）交易記錄保管及查詢服務

我們就您在本平台上的交易所收集的相關數據，包括但不限於交易記錄。如您需查詢您的訂單交易記錄，可在平台“訂單管理”中查詢您的個人交易明細，具體包括：交易對方暱稱、下單時間、訂單號、交易信息、數字資產種類及數量等。

（四） 客服及爭議處理

當您與我們聯繫或提出售中售後、爭議糾紛處理申請時，為了保障您的賬戶及系統安全，我們需要您提供必要的個人信息以核驗您的會員身份。

為便於與您聯繫、盡快幫助您解決問題或記錄相關問題的處理方案及結果，我們可能會保存您與我們的通信記錄及相關內容（包括賬號信息、訂單信息、您為了證明相關事實提供的其他信息，或您留下的聯繫方式信息），如果您針對具體訂單進行諮詢、投訴或提供建議的，我們會使用您的賬號信息和訂單信息。

為了提供服務及改進服務質量的合理需要，我們還可能使用的您的其他信息，包括您與客服聯繫時您提供的相關信息，您參與問卷調查時向我們發送的問卷答复信息。

（五）其他

1、若你提供的信息中含有其他用戶的個人信息，在向本平台提供這些個人信息之前，您需確保您已經取得合法的授權。若其中涉及兒童個人信息的，您需在發布前取得對應兒童監護人的同意，前述情形下監護人有權通過本政策第九條的途徑聯繫我們，要求更正或刪除涉及兒童個人信息的內容。

2、若我們將信息用於本政策未載明的其他用途，或者將基於特定目的收集而來的信息用於其他目的，或者我們主動從第三方處獲取您的個人信息，均會事先獲得您的同意。

若我們從第三方處間接獲取您的信息的，我們會在收集前明確以書面形式要求該第三方在已依法取得您同意後收集個人信息，並向您告知共享的信息內容，且涉及敏感信息的在提供給我們使用前需經過您的明確確認，要求第三方對個人信息來源的合法性和合規性作出承諾，如第三方有違反行為的，我們會明確要求對方承擔相應法律責任；同時，我們對個人信息會進行安全加固（包括敏感信息報備、敏感信息加密存儲、訪問權限控制等）。我們會使用不低於我們對自身用戶個人信息同等的保護手段與措施對間接獲取的個人信息進行保護。

3、徵得授權同意的例外

您充分理解並同意，我們在以下情況下收集、使用您的個人信息無需您的授權同意，且我們可能不會響應您提出的更正/修改、刪除、註銷、撤回同意、索取信息的請求：

（1）與國家安全、國防安全有關的；

（2）與公共安全、公共衛生、重大公共利益有關的；

（3）與犯罪偵查、起訴、審判和判決執行等司法或行政執法有關的；

（4）出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；

（5）您自行向社會公眾公開的個人信息；

（6）從合法公開披露的信息中收集個人信息的，如合法的新聞報導、政府信息公開等渠道。

（7）根據與您簽訂和履行相關協議或其他書面文件所必需的；

（8）用於維護所提供的產品及/或服務的安全穩定運行所必需的，例如發現、處置產品及/或服務的故障；

（9）為合法的新聞報導所必需的；

（10）學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的個人信息進行去標識化處理的；

（11）法律法規規定的其他情形。

請知悉，根據適用的法律，若我們對個人信息採取技術措施和其他必要措施進行處理，使得數據接收方無法重新識別特定個人且不能複原，或我們可能會對收集的信息進行去標識化地研究、統計分析和預測，用於改善本平台的內容和佈局，為商業決策提供產品或服務支撐，以及改進我們的產品和服務（包括使用匿名數據進行機器學習或模型算法訓練），則此類處理後數據的使用無需另行向您通知並徵得您的同意。

4、如我們停止運營本平台產品或服務，我們將及時停止繼續收集您個人信息的活動，將停止運營的通知以逐一送達或公告的形式通知您，並對我們所持有的與已關停業務相關的個人信息進行刪除或匿名化處理。

二、我們如何使用Cookie

如果您的瀏覽器或瀏覽器附加服務允許，您可修改對Cookie的接受程度或拒絕我們的Cookie。有關詳情，請參見 AboutCookies.org。但如果您這麼做，在某些情況下可能會影響您安全訪問我們的平台，且可能需要在每一次訪問我們的平台時更改用戶設置。您理解並且同意：

1、 當您到訪我們平台時, 我們通過cookies使用Google Stats去記錄我們的業績以及核對在線廣告的效果。 Cookie是發送到您的瀏覽器上並在您的電腦硬盤驅動器上存儲的小量數據。只有當您使用您的電腦進入我們平台時,Cookie才能夠被發送到您的電腦硬盤上。

2、Cookies常用於記錄訪問者瀏覽我們平台上的各個項目時的習慣以及偏好。 Cookies所蒐集的資料是不記名的集體統計數據, 不載有個人資料。

3、Cookies不能用於取得您的硬盤上的數據、您的電郵地址、及您的私人數據，其可使本平台或服務商系統識別到您的瀏覽器並捕捉和記憶信息。大多數瀏覽器都預設為可以接受Cookies。您可以選擇將您的瀏覽器設定為不接受Cookies,或如果Cookies一被裝上就通知您。不過, 若設定為禁止Cookies, 您或許便不能啟動或使用我們平台的某些功能。

三、我們如何共享、轉讓、公開披露您的信息

（一）共享

我們不會與本平台服務提供者以外的公司、組織和個人共享您的個人信息，但以下情況除外：

1、在法定情形下的共享：我們可能會根據法律法規規定、訴訟、爭議解決需要，或按行政、司法機關依法提出的要求，對外共享您的個人信息。

2、在獲取明確同意的情況下共享：獲得您的明確同意後，我們會與其他方共享您的個人信息。

3、在您主動選擇情況下共享：我們會根據您的選擇，將您的訂單信息中與交易有關的必要信息共享給數字資產的交易對方，以實現您的交易及售後服務需求。

4、與授權合作夥伴共享：我們的關聯方、幫助我們經營我們的平台、開展業務、或者向您提供服務的受信任的第三方，只要這些當事方同意將這些信息保密；當我們相信披露的信息是適當的，是遵守法律、法規、規章制度的或來自於法院或他主管當局的命令、執行我們的平台策略，正常運行平台所需要，關聯方提供服務所需或保護我們或他人的權利、財產或安全的。

（二）轉讓

我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：

1、在獲取明確同意的情況下轉讓：獲得您的明確同意後，我們會向其他方轉讓您的個人信息；

2、在本平台發生合併、收購或破產清算情形，或其他涉及合併、收購或破產清算情形時，如涉及到個人信息轉讓，我們會要求新的持有您個人信息的公司、組織繼續受本政策的約束，否則我們將要求該公司、組織和個人重新向您徵求授權同意。

（三）公開披露

我們僅會在以下情況下，公開披露您的個人信息：

1、獲得您明確同意或基於您的主動選擇，我們可能會公開披露您的個人信息；

2、如果我們確定您出現違反法律法規或嚴重違反本平台相關協議及規則的情況，或為保護本平台用戶或公眾的人身財產安全免遭侵害，我們可能依據法律法規或徵得您同意的情況下披露關於您的個人信息，包括相關違規行為以及本平台已對您採取的措施。

（四）共享、轉讓、公開披露個人信息時事先徵得授權同意的例外

以下情形中，共享、轉讓、公開披露您的個人信息無需事先徵得您的授權同意：

1、與國家安全、國防安全有關的；

2、與公共安全、公共衛生、重大公共利益有關的；

3、與犯罪偵查、起訴、審判和判決執行等司法或行政執法有關的；

4、出於維護您或其他個人的生命、財產等重大合法權益但又很難得到本人同意的；

5、您自行向社會公眾公開的個人信息；

6、從合法公開披露的信息中收集個人信息的，如合法的新聞報導、政府信息公開等渠道。

請知悉，根據適用的法律，若我們對個人信息採取技術措施和其他必要措施進行處理，使得數據接收方無法重新識別特定個人且不能複原，則此類處理後數據的共享、轉讓、公開披露無需另行向您通知並徵得您的同意。

四、我們如何保護您的信息

1、我們實施妥善的實物、電子、管理及技術方面的措施來保護和保障您的個人資料的安全。我們盡力確保通過我們平台所蒐集的任何個人資料皆免於任何與我們無關的第三者的滋擾。我們採取的安全措施包括但不限於：

（1）實物措施：存有您個人資料的記錄會被存放在有鎖的地方。

（2）電子措施：存有您個人資料的電腦數據會被存放在受嚴格登入限制的電腦系統和存儲媒體上。

（3）管理措施：我們內部設立有對用戶信息進行安全保護的相關部門，並建立了相關內控制度，對可能接觸到您的信息的工作人員採取嚴格授權原則，只有經我們授權的職員才能接觸到您的個人資料, 這些職員需要遵守我們個人資料保密的內部守則。此外，我們不斷對工作人員培訓相關法律法規及隱私安全準則和安全意識強化宣導，並定期組織內部相關人員進行應急響應培訓和應急演練，使其掌握崗位職責和應急處置策略和規程

（4）技術措施：可能採用如Secure Socket Layer Encryption這種加密技術來輸送您的個人資料。

（5）安全措施：為保障您的信息安全，我們致力於使用各種現行通用的安全技術及配套的管理體係來盡量降低您的信息被洩露、毀損、誤用、非授權訪問、非授權披露和更改的風險。例如：通過網絡安全層軟件（SSL）進行加密傳輸、信息加密存儲、嚴格限制數據中心的訪問。傳輸和存儲個人敏感信息（含個人生物識別信息）時，我們將採用加密、權限控制、去標識化、脫敏化等安全措施。

（6）其它措施：定期審查我們的個人資料收集，存儲和處理流程; 並根據必要的原則限制我們的員工和供應商對您數據的訪問權限，且我們的員工和供應商均需遵守嚴格的合同保密義務。 。

2、若您知悉我們的平台上有任何安全方面的漏洞, 請馬上聯繫我們, 使我們可以盡快採取妥適的行動。

3、儘管實施了上述技術和保安的措施, 我們不能保證資料在互聯網上的輸送絕對安全, 因此我們不能絕對保證您通過我們平台提供給我們的個人資料在一切時候都是安全的。我們將不定期更新並公開安全風險、個人信息安全影響評估報告等有關內容，您可通過本平台公告方式獲得。

五、您如何管理您的信息

您可以通過以下方式訪問及管理您的信息：

（一）查詢、更正和刪除您的信息

您有權根據要求獲得您的個人資料副本，並確定我們掌握的關於您的信息是否準確並且是最新的。如果您的任何個人數據不准確，您可以要求更新您的信息。您也可以要求刪除您的個人資料，但我們可能會在某些情況下拒絕您的刪除請求，例如出於法律要求或其他法律目的。有關數據訪問，更正或刪除請求，您可以通過第八條中的聯繫方式聯繫我們。

為了響應數據訪問，更正或刪除請求，我們將驗證請求方的身份，以確保他/她在法律上有權提出此類請求。雖然我們的目標是免費回复這些請求，但我們保留在您的請求重複或繁重時向您收取合理費用的權利。

（二）改變您授權同意的範圍

每個業務功能需要一些基本的個人信息才能得以完成（見本隱私權政策“第一部分”）。除此之外，您可以通過第八條中的聯繫方式聯繫我們給予或收回您的授權同意。

當您收回同意後，我們將不再處理相應的個人信息。但您收回同意的決定，不會影響此前基於您的授權而開展的個人信息處理。

（三）註銷您的賬戶

您可以通過第八條中的聯繫方式聯繫我們來申請註銷您的賬戶；在您主動註銷賬戶之後，我們將停止為您提供產品或服務，根據適用法律的要求刪除您的個人信息，或使其匿名化處理。

（四）響應您的上述請求

為保障安全，您可能需要提供書面請求，或以其他方式證明您的身份。我們可能會先要求您驗證自己的身份，然後再處理您的請求。

我們將在15天內做出答复。如您不滿意，還可以通過我們提供的聯繫方式發起投訴。

對於您合理的請求，我們原則上不收取費用，但對多次重複、超出合理限度的請求，我們將酌情收取一定費用。對於與您的身份不直接關聯的信息、無端重複信息，或者需要過多技術手段（例如，需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或者不切實際的請求，我們可能會予以拒絕。

在以下情形中，按照法律法規要求，我們將無法響應您的請求：

1、與國家安全、國防安全有關的；

2、與公共安全、公共衛生、重大公共利益有關的；

3、與犯罪偵查、起訴、審判和執行判決等有關的；

4、有充分證據表明個人信息主體存在主觀惡意或濫用權利的；

5、響應您的請求將導致您或其他個人、組織的合法權益受到嚴重損害的；

6、涉及商業秘密的。

六、我們如何處理未成年人的信息

本平台向兒童開放，如果我們發現或懷疑用戶未滿14周歲，我們會要求用戶關閉其帳戶，且不會允許該用戶繼續使用平台產品或服務。我們會盡快刪除有關資料。如果您知道有任何14周歲以下的人使用我們的服務，請通知我們，以便我們採取行動阻止他們獲取我們的產品或服務。

對於已滿14歲但尚未滿18周歲的未成年人，我們期望父母或監護人指導未成年人使用我們的服務。我們將根據相關法律法規的規定保護未成年人的信息的保密性及安全性。

如您為未成年人，建議請您的父母或監護人閱讀本政策，並在徵得您父母或監護人同意的前提下使用我們的服務或向我們提供您的信息。對於經父母或監護人同意而收集您的信息的情況，我們只會在受到法律允許、父母或監護人明確同意或者保護您的權益所必要的情況下使用或公開披露此信息。如您的監護人不同意您按照本政策使用我們的服務或向我們提供信息，請您立即終止使用我們的服務並及時通知我們，以便我們採取相應的措施。

如您為未成年人的父母或監護人，當您對您所監護的未成年人的信息處理存在疑問時，請通過本政策公佈的聯繫方式聯繫我們。

七、本隱私權政策如何更新

我們的隱私權政策可能變更。

未經您明確同意，我們不會限制您按照本隱私政策所應享有的權利。我們會在官方渠道上發布對隱私政策所做的任何變更。如果您不同意修訂的內容，您應停止立即訪問本平台。當隱私政策的更新版本發布，您持續訪問本平台顯示和表明您同意該更新的內容，並同意遵守該更新的隱私政策。

對於重大變更，我們還會提供更為顯著的通知（包括我們會通過本平台公示的方式進行通知甚至向您提供彈窗提示）。

本政策所指的重大變更包括但不限於：

1、我們的服務模式發生重大變化。如處理個人信息的目的、處理的個人信息類型、個人信息的使用方式等；

2、我們在控制權等方面發生重大變化。如併購重組等引起的信息控制者變更等；

3、個人信息共享、轉讓或公開披露的主要對象發生變化；

4、您參與個人信息處理方面的權利及其行使方式發生重大變化；

5、我們負責處理個人信息安全的責任部門、聯絡方式及投訴渠道發生變化；

6、個人信息安全影響評估報告表明存在高風險。

            </pre>
            </template>
            <template v-else>
                <pre>
                    Introduction and Disclaimer

We are a company incorporated in the United States of America under the relevant laws of the United States of America, which operates the Global APP (hereinafter referred to as the "Platform" or the "Platform"), which is a platform specifically designed for users to trade in digital assets and provide related services (hereinafter referred to as the "Service" or the "Services"). The "Service" or the "Services"), which is a specialized platform for users to trade digital assets and provide related services (hereinafter referred to as the "Service" or the "Services"). For ease of presentation, the Company and the Platform are collectively referred to in this Agreement as "we" or other first-person references, and as long as a natural person or other entity logging on to the Platform is a subscriber to the Platform, "you" or other second-person references will be used hereinafter, and we and you will be collectively referred to as the "Parties". We and you are collectively referred to as the "Parties", and we or you alone are referred to as the "Party".

We fully understand and respect the importance of your personal information to you, and we will take appropriate security measures to protect the security of your personal information in accordance with laws and regulations. In view of this, we have developed this Privacy Policy (hereinafter referred to as "this Policy / this Privacy Policy") and would like to remind you of it:

This Policy applies to all products and services provided by us. If we provide you with a product or service that has a separate privacy policy, that product or service will be governed by its own separate privacy policy; if our product or service does not have a separate privacy policy, this Policy will apply.

In particular, this Policy does not apply to services provided to you by other third parties, which will be governed by their own privacy policies that are separately stated to you.

Before using any of our products or services, please be sure to read and thoroughly understand this Policy, in particular the terms highlighted in bold/underlined, you should focus on reading them and confirm that you fully understand and agree to them before you begin to use them. If you have any questions, comments or suggestions regarding the contents of this Policy, you may contact us through any of our official contact methods. If you do not agree with any of the terms of this Policy, you should immediately stop accessing the Platform.

Part I Definitions

Personal Information: refers to all kinds of information recorded by electronic or other means that can identify a specific natural person or reflect the activities of a specific natural person, either alone or in combination with other information.

Personal Sensitive Information: Personal information including document number, personal biometric information, bank account number, property information, whereabouts, transaction information, information on children under 14 years of age (including), etc. (We will highlight specific personal sensitive information in bold in this Privacy Policy).

Personal Information Deletion: The act of removing personal information from systems involved in the performance of daily business functions so that it remains in a state where it cannot be retrieved or accessed.

Child: A minor under the age of fourteen.

Part 2 Privacy Policy

This privacy policy section will help you understand the following:

1. How we collect and use your information

2. How we use cookies and similar technologies

3. How we share, transfer and publicly disclose your information

4. How we protect your information

5. How do you manage your information?

6. How we handle minors’ information

7. How to update this privacy policy

8. How to contact us

1. How we collect and use your information

When you use our products and/or services, the personal information we need/may need to collect and use includes the following two types:

1. In order to provide you with the basic functions of our products and/or services, you must authorize us to collect and use the necessary information. If you refuse to provide corresponding information, you will not be able to use our products and/or services normally;

2. In order to provide you with additional functions of our products and/or services, you may choose to authorize us to collect and use the information. If you refuse to provide it, you will not be able to use the relevant additional functions normally or achieve the functional effects we intend to achieve, but it will not affect your normal use of the basic functions of our products and/or services.

You understand and agree:

1. We are committed to creating a variety of products and services to meet your needs. Because we provide you with many types of products and services, and the specific range of products/services that different users choose to use are different, accordingly, the basic/additional functions and the type and scope of personal information collected and used will be different. Please use Specific product/service functions shall prevail;

2. In order to bring you a better product and service experience, we continue to work hard to improve our technology. As a result, we may launch new or optimized functions from time to time, which may require us to collect and use new personal information or Change the purpose or method of using personal information. In this regard, we will separately explain to you the purpose, scope and use of the corresponding information by updating this policy, pop-up windows, page prompts, etc., and provide you with a way to choose your consent, and after obtaining your express consent Collect and use. During this process, if you have any questions, comments or suggestions, you can contact us through the various contact methods provided on this platform, and we will answer you as soon as possible.

We will collect and use your personal information to implement the functions described below in this policy:

(I) Help you register and provide you with the services provided by this platform;

1. Registration service

We provide you with basic membership services through this platform account. In order to register as a member and use our membership services, you need to fill in and provide the following information when applying for registration:

(1) Identity information. This information can help us verify whether you are eligible to register as a member of this platform, including but not limited to your name, your residential address, other different certificates issued by your country or government that prove your identity information, as well as the numbers involved and all other information that can help us verify your identity (hereinafter collectively referred to as "identity information"). The identity information varies depending on whether you are an individual user or an institutional user. The information we collect from users from different countries or regions may be different according to the laws and regulations of different jurisdictions. The following information and data are used as an indicative example:

Individual users: nationality, full legal name (including former names and local language names), ID number, email address, telephone number, social security number ("SSN"), passport number or any government-issued ID number, date of birth, ID (such as passport, driver's license or government-issued ID).

Institutional users: company legal name (including legal name in local language), registration information, business telephone number, personal information of authorized representatives and authorized users, main place of business, personal information of all beneficial owners), shareholder/beneficial owner's identification documents (passport or government-issued documents), company structure, company establishment-related documents, board resolutions, source of funds declaration, risk and compliance control instructions and other information or documents required by the platform.

(2) Service information. This information helps us contact you and smoothly provide you with different modes of digital asset transaction services, including but not limited to your personal identity information, contact information, transaction information, and your debit card information and/or other account information (hereinafter collectively referred to as "Service Information"). At the same time, please understand that the services we provide to you are constantly updated and developed. If you choose to use other services not covered in the above descriptions, and we need to collect additional information from you for such services, we will explain the scope and purpose of information collection to you through page prompts, interactive processes, agreement agreements, pop-up prompts, etc., and obtain your consent.

If you only need to use the browsing service, you do not need to register as our member and provide the above information.

2. Other registration services

Real-name authentication: After you register and log in, you need to enter the "Personal Center" to complete the real-name authentication in the identity authentication. You need to provide the platform with your real name, ID number or passport number during the real-name authentication process.

Advanced authentication: When your transaction amount on this platform exceeds a certain limit, the platform will require you to perform advanced authentication in order to maintain the security of your assets. In addition to the real-name authentication information, you agree that the platform will perform advanced authentication of "face recognition" on you. Face recognition will involve the platform's collection of your relevant video streams or facial images.

Account information display: If you already have an account on this platform, we may display your personal information as mentioned above (real-name authentication only displays the result of whether the authentication is passed) and the operations you perform in the products and services associated with the platform account in the service, including displaying your personal information and transaction orders in a centralized manner through this platform account. We will respect the choices you make about the platform account settings.

(II) Security management services and service information push services

When you use the services provided by this platform or visit this platform, in order to ensure the security of your transactions and the safe and stable operation of the services, we need to automatically receive and record your web browser information, device information such as the type of device you use to log in to your account, device identification symbols such as model version, IP address and records on the web pages you request to access, location, network environment, device fingerprint information, service log information, software information, location information, etc.

Please note that individual device information is information that cannot identify a specific natural person. If we combine this type of non-personal information with other information to identify a specific natural person, or use it in combination with personal information, during the period of combined use, this type of non-personal information will be regarded as personal information. Unless otherwise authorized by you or otherwise required by laws and regulations, we will anonymize and de-identify this type of information.

In order to provide you with more convenient information display and push services that are more in line with your personalized needs, we may extract your preference characteristics based on your device information and your browsing and use information of this platform or client in the future, and produce indirect crowd portraits based on feature tags for display, push information and possible commercial advertisements. If you do not agree to receive such push information or withdraw your consent to collect personal information, you can contact us according to the contact information provided in this policy.

We strive to protect your browsing experience. If you do not want to receive commercial advertisements from us, you can unsubscribe or close them by replying to the SMS message or other methods we provide.

In addition, we will also use your above information to continuously improve and optimize the above functions.

(III) Transaction record storage and inquiry service

We collect relevant data about your transactions on this platform, including but not limited to transaction records. If you need to inquire about your order transaction records, you can check your personal transaction details in the "Order Management" of the platform, including: counterparty nickname, order time, order number, transaction information, digital asset type and quantity, etc.

(IV) Customer Service and Dispute Resolution

When you contact us or submit an application for after-sales service or dispute resolution, in order to protect the security of your account and system, we need you to provide necessary personal information to verify your membership status.

In order to facilitate contact with you, help you solve problems as quickly as possible, or record solutions and results of related problems, we may save your communication records and related content (including account information, order information, other information you provide to prove relevant facts, or contact information you leave). If you consult, complain or provide suggestions for specific orders, we will use your account information and order information.

For the reasonable needs of providing services and improving service quality, we may also use your other information, including the relevant information you provide when you contact customer service, and the questionnaire response information you send to us when you participate in the questionnaire survey.

(V) Others

1. If the information you provide contains personal information of other users, you must ensure that you have obtained legal authorization before providing this personal information to this platform. If it involves children's personal information, you need to obtain the consent of the corresponding child's guardian before publishing. In the above situation, the guardian has the right to contact us through the means of Article 9 of this Policy to request correction or deletion of content involving children's personal information.

2. If we use the information for other purposes not specified in this Policy, or use the information collected for a specific purpose for other purposes, or we actively obtain your personal information from a third party, we will obtain your consent in advance.

If we obtain your information indirectly from a third party, we will clearly require the third party in writing to collect personal information after obtaining your consent in accordance with the law before collection, and inform you of the shared information content. In addition, sensitive information must be clearly confirmed by you before it is provided to us for use, and the third party is required to make a commitment to the legitimacy and compliance of the source of personal information. If the third party violates the law, we will clearly require the other party to bear the corresponding legal responsibility; at the same time, we will strengthen the security of personal information (including sensitive information reporting, encrypted storage of sensitive information, access permission control, etc.). We will protect the indirectly obtained personal information with protection means and measures no less than those we use for our own users' personal information.

3. Exceptions to obtaining authorization and consent

You fully understand and agree that we do not need your authorization and consent to collect and use your personal information in the following circumstances, and we may not respond to your requests for correction/modification, deletion, cancellation, withdrawal of consent, or request for information:

(1) Related to national security and national defense security;

(2) Related to public safety, public health, and major public interests;

(3) Related to judicial or administrative enforcement such as criminal investigation, prosecution, trial, and execution of judgments;

(4) For the purpose of protecting your or other individuals' major legal rights and interests such as life and property, but it is difficult to obtain the consent of the person concerned;

(5) Personal information that you have disclosed to the public on your own;

(6) Personal information collected from legally disclosed information, such as legal news reports, government information disclosure, and other channels.

(7) Necessary in accordance with the signing and performance of relevant agreements or other written documents with you;

(8) Necessary to maintain the safe and stable operation of the products and/or services provided, such as discovering and handling product and/or service failures;

(9) Necessary for legitimate news reporting;

(10) Necessary for academic research institutions to conduct statistical or academic research based on public interests, and when providing the results of academic research or descriptions to the outside, the personal information contained in the results is de-identified;

(11) Other circumstances stipulated by laws and regulations.

Please be aware that, in accordance with applicable laws, if we take technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore it, or we may conduct de-identified research, statistical analysis and prediction on the collected information to improve the content and layout of this platform, provide product or service support for business decisions, and improve our products and services (including using anonymous data for machine learning or model algorithm training), then the use of such processed data does not require additional notification to you and your consent.

4. If we cease to operate the products or services of this platform, we will promptly stop collecting your personal information, notify you of the cessation of operation by individual delivery or announcement, and delete or anonymize the personal information we hold related to the discontinued business.

2. How we use cookies

If your browser or browser add-on allows, you can modify your acceptance of cookies or reject our cookies. For details, please see AboutCookies.org. However, if you do this, it may affect your secure access to our platform in some cases, and you may need to change your user settings every time you visit our platform. You understand and agree:

1. When you visit our platform, we use Google Stats through cookies to record our performance and verify the effectiveness of online advertising. Cookies are small amounts of data sent to your browser and stored on your computer's hard drive. Cookies can only be sent to your computer's hard drive when you use your computer to access our platform.

2. Cookies are often used to record the habits and preferences of visitors when browsing various items on our platform. The data collected by Cookies are anonymous collective statistics and do not contain personal information.

3. Cookies cannot be used to obtain data on your hard drive, your email address, and your private data. They can enable this platform or service provider system to recognize your browser and capture and remember information. Most browsers are set to accept Cookies by default. You can choose to set your browser to not accept Cookies, or notify you if Cookies are installed. However, if set to prohibit Cookies, you may not be able to activate or use certain features of our platform.

III. How we share, transfer, and publicly disclose your information

(I) Sharing

We will not share your personal information with companies, organizations, and individuals other than the service providers of this platform, except in the following circumstances:

1. Sharing under legal circumstances: We may share your personal information externally in accordance with the provisions of laws and regulations, litigation, dispute resolution needs, or in accordance with the requirements of administrative and judicial authorities in accordance with the law.

2. Sharing with explicit consent: After obtaining your explicit consent, we will share your personal information with other parties.

3. Sharing with your active choice: We will share the necessary transaction-related information in your order information with the counterparty of the digital asset based on your choice to meet your transaction and after-sales service needs.

4. Share with authorized partners: Our affiliates, trusted third parties who help us operate our platform, conduct business, or provide services to you, as long as these parties agree to keep such information confidential; when we believe that the disclosed information is appropriate, is in compliance with laws, regulations, rules and regulations or orders from courts or other competent authorities, implements our platform strategy, is required for the normal operation of the platform, is required for affiliates to provide services, or protects the rights, property or security of us or others.

(II) Transfer

We will not transfer your personal information to any company, organization or individual, except in the following circumstances:

1. Transfer with explicit consent: We will transfer your personal information to other parties with your explicit consent;

2. In the event of a merger, acquisition or bankruptcy liquidation on this platform, or other circumstances involving merger, acquisition or bankruptcy liquidation, if personal information transfer is involved, we will require the new company or organization holding your personal information to continue to be bound by this policy, otherwise we will require the company, organization or individual to re-ask for your authorization and consent.

(III) Public Disclosure

We will only publicly disclose your personal information under the following circumstances:

1. With your explicit consent or based on your active choice, we may publicly disclose your personal information;

2. If we determine that you have violated laws and regulations or seriously violated the relevant agreements and rules of this platform, or to protect the personal and property safety of users of this platform or the public from infringement, we may disclose your personal information in accordance with laws and regulations or with your consent, including relevant violations and measures taken by this platform against you.

(IV) Exceptions to obtaining prior authorization and consent when sharing, transferring, or publicly disclosing personal information

In the following circumstances, sharing, transferring, or publicly disclosing your personal information does not require your prior authorization and consent:

1. Related to national security and national defense security;

2. Related to public safety, public health, and major public interests;

3. Related to judicial or administrative enforcement such as criminal investigation, prosecution, trial, and execution of judgments;

4. For the purpose of protecting your or other individuals’ major legal rights and interests such as life and property, but it is difficult to obtain the consent of the person concerned;

5. Personal information that you have disclosed to the public on your own;

6. Personal information collected from legally disclosed information, such as legal news reports, government information disclosure, and other channels.

Please be aware that, in accordance with applicable laws, if we take technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore it, the sharing, transfer, and public disclosure of such processed data does not require additional notification to you and your consent.

IV. How we protect your information

1. We implement appropriate physical, electronic, management and technical measures to protect and safeguard the security of your personal data. We strive to ensure that any personal data collected through our platform is free from interference from any third party unrelated to us. The security measures we take include but are not limited to:

(1) Physical measures: Records containing your personal data will be stored in a locked place.

(2) Electronic measures: Computer data containing your personal data will be stored on computer systems and storage media with strict login restrictions.

(3) Management measures: We have set up relevant departments to protect user information and have established relevant internal control systems. We have adopted strict authorization principles for staff who may have access to your information. Only staff authorized by us can access your personal data. These staff need to abide by our internal rules for confidentiality of personal data. In addition, we continuously train staff on relevant laws and regulations, privacy and security guidelines, and strengthen security awareness, and regularly organize internal relevant personnel to conduct emergency response training and emergency drills to enable them to master job responsibilities and emergency handling strategies and procedures.

(4) Technical measures: Encryption technology such as Secure Socket Layer Encryption may be used to transmit your personal data.

(5) Security measures: To protect your information security, we are committed to using various currently available security technologies and supporting management systems to minimize the risk of your information being leaked, damaged, misused, unauthorized accessed, disclosed, and altered. For example: encrypted transmission through network security layer software (SSL), encrypted information storage, and strict restrictions on data center access. When transmitting and storing personal sensitive information (including personal biometric information), we will adopt encryption, permission control, de-identification, desensitization and other security measures.

(6) Other measures: Regularly review our personal data collection, storage and processing processes; and limit our employees' and suppliers' access to your data as necessary, and our employees and suppliers must comply with strict contractual confidentiality obligations.

2. If you are aware of any security vulnerabilities on our platform, please contact us immediately so that we can take appropriate action as soon as possible.

3. Despite the implementation of the above technical and security measures, we cannot guarantee that the transmission of data on the Internet is absolutely secure, so we cannot absolutely guarantee that the personal data you provide to us through our platform is safe at all times. We will update and publish security risks, personal information security impact assessment reports and other related content from time to time, and you can obtain them through announcements on this platform.

V. How you manage your information

You can access and manage your information in the following ways:

(I) Query, correct and delete your information

You have the right to obtain a copy of your personal data upon request and to determine whether the information we have about you is accurate and up-to-date. If any of your personal data is inaccurate, you can request to update your information. You can also request to delete your personal data, but we may deny your deletion request in certain circumstances, such as due to legal requirements or other legal purposes. For data access, correction or deletion requests, you can contact us through the contact information in Article 8.

In order to respond to data access, correction or deletion requests, we will verify the identity of the requesting party to ensure that he/she is legally entitled to make such a request. Although our goal is to respond to these requests free of charge, we reserve the right to charge you a reasonable fee if your request is repetitive or onerous.

(II) Changing the scope of your authorization

Each business function requires some basic personal information to be completed (see "Part I" of this Privacy Policy). In addition, you can contact us through the contact information in Article 8 to give or withdraw your authorization consent.

When you withdraw your consent, we will no longer process the corresponding personal information. However, your decision to withdraw your consent will not affect the personal information processing previously carried out based on your authorization.

(III) Cancelling your account

You can contact us through the contact information in Article 8 to apply for cancellation of your account; after you actively cancel your account, we will stop providing you with products or services, delete your personal information, or anonymize it in accordance with the requirements of applicable laws.

(IV) Responding to your above request

For security reasons, you may need to provide a written request or prove your identity in other ways. We may ask you to verify your identity before processing your request.

We will respond within 15 days. If you are not satisfied, you can also file a complaint through the contact information we provide.

In principle, we do not charge any fees for your reasonable requests, but we will charge a certain fee at our discretion for repeated requests that exceed reasonable limits. We may reject requests for information that is not directly related to your identity, is unnecessarily repetitive, or requires excessive technical effort (for example, developing a new system or fundamentally changing existing practices), poses a risk to the legal rights of others, or is impractical.

In the following circumstances, we will not be able to respond to your request in accordance with laws and regulations:

1. Related to national security and national defense security;

2. Related to public security, public health, and major public interests;

3. Related to criminal investigation, prosecution, trial, and execution of judgments;

4. There is sufficient evidence to show that the subject of personal information has subjective malice or abuses rights;

5. Responding to your request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations;

6. Involving trade secrets.

VI. How we handle information of minors

This platform is open to children. If we find or suspect that a user is under the age of 14, we will ask the user to close his or her account and will not allow the user to continue to use the platform products or services. We will delete the relevant data as soon as possible. If you know that anyone under the age of 14 is using our services, please notify us so that we can take action to prevent them from obtaining our products or services.

For minors who are over 14 years old but not yet 18 years old, we expect parents or guardians to guide minors in using our services. We will protect the confidentiality and security of minors' information in accordance with relevant laws and regulations.

If you are a minor, we recommend that you ask your parents or guardians to read this policy and use our services or provide us with your information with the consent of your parents or guardians. If we collect your information with the consent of your parents or guardians, we will only use or disclose this information when permitted by law, with the explicit consent of your parents or guardians, or when necessary to protect your rights and interests. If your guardian disagrees with your use of our services or provision of information to us in accordance with this policy, please immediately stop using our services and notify us in a timely manner so that we can take appropriate measures.

If you are the parent or guardian of a minor, when you have questions about the information processing of the minor you are supervising, please contact us through the contact information published in this policy.

VII. How to update this privacy policy

Our privacy policy may change.

Without your explicit consent, we will not restrict your rights under this privacy policy. We will publish any changes to the privacy policy on official channels. If you disagree with the revised content, you should stop visiting this platform immediately. When an updated version of the privacy policy is released, your continued access to this platform will display and indicate that you agree to the updated content and agree to abide by the updated privacy policy.

For major changes, we will also provide more prominent notices (including notifications through public announcements on this platform and even pop-up reminders to you).

Major changes referred to in this policy include but are not limited to:

1. Major changes in our service model. Such as the purpose of processing personal information, the type of personal information processed, the way personal information is used, etc.;

2. We have major changes in control rights, etc. Such as changes in information controllers caused by mergers and acquisitions and reorganizations;

3. Changes in the main objects of personal information sharing, transfer or public disclosure;

4. Major changes in your rights to participate in personal information processing and the way you exercise them;

5. Changes in the responsible department, contact methods and complaint channels for handling personal information security;

6. The personal information security impact assessment report shows that there is a high risk.
                </pre>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: localStorage.getItem('lang') || 'en',
        };
    },
    created() {
    },
    methods: {

    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    pre {
        // 超出换行
        white-space: pre-wrap;
        word-wrap: break-word;
        // 字体如何向左对齐呢？
        text-align: justify;
        text-justify: inter-ideograph;
        // 字体颜色
        color: #333;
        padding: 15px;
    }

}
</style>
